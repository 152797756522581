import { FC, Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

import MainLoader from "./components/UI/MainLoader";

const Home = lazy(() => import("./containers/Home"));
const Success = lazy(() => import("./containers/Success"));
const New = lazy(() => import("./containers/New"));
const Dev = lazy(() => import("./containers/Dev"));

const App: FC = () => {
  return (
    <Suspense fallback={<MainLoader />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="foodics-success" element={<Success />} />
        <Route path="foodics-new" element={<New />} />
        <Route path="foodics-dev" element={<Dev />} />
      </Routes>
    </Suspense>
  );
};

export default App;
